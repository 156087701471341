/*****************************************************************************************
 * 설명 : 사주·운세
 * URI : /saju
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';

import { baseImgUrl } from 'config/config';

import Restful from 'service/restful';

import Layout from 'pages/homepage/layout/layout';

import './saju.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Saju = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const navigate = useNavigate();

  const [dayFortune, setDayFortune] = useState({});
  const [list, setList] = useState([]);
  const [info, setInfo] = useState([]);

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  const getDayFortune = () => {
    let params = {
      program: 'home',
      service: 'main',
      version: '1.0',
      action: 'getDayFortuneList'
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result ) {
        setDayFortune(response.data.var);

        if( response.data.info && response.data.info.length > 0 ) {
          setInfo(response.data.info);
        } else {
          setInfo([]);
        }

      } else {
        setDayFortune({})
      }
    });
  }

  /***************************************************************************************
   * 설명 : 서비스 데이터 로딩 처리
  ***************************************************************************************/
  const getList = () => {
    let params = {
      program: 'home',
      service: 'main',
      version: '1.0',
      action: 'getSajuList'
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);

      } else {
        setList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    // 오늘의 운세 가져오기
    getDayFortune();

    // 사주 서비스 가져오기
    getList();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <div className="saju">
        <section className="main-menu mobile-only">
          <ul className="menu clearfix">
            <li><NavLink to="/" className={window.location.pathname === '/' ? 'depth1 on' : 'depth1'}>추천</NavLink></li>
            <li><NavLink to="/saju" className={window.location.pathname === '/saju' ? 'depth1 on' : 'depth1'}>사주·운세</NavLink></li>
            <li><NavLink to="/gunghap" className={window.location.pathname === '/gunghap' ? 'depth1 on' : 'depth1'}>애정운·궁합</NavLink></li>
          </ul>
        </section>

        <section className="top-visual">
          <section className="info-div">
            <h1>집을 나서기 전 오늘의 운세를 확인하세요!</h1>
            <p className="txt">일진이 사나울 경우 개운 부적을 무료로 발급해드립니다.</p>

            <section className="info-box">
              <h5>오늘의 운세</h5>
              <div className="day">
                <span className="day1">{dayFortune?.iljin_yangdate_str}</span>
                <span className="day2">{dayFortune?.iljin_weekday_kr}요일</span>
                음력 {dayFortune?.iljin_umdate_str}
              </div>

              <div className="clearfix">
                <div className="box1 clearfix">
                  <div className="date1">
                    <div className="th">年</div>
                    <div className="td">
                      <p>{dayFortune?.my_year_h}{dayFortune?.my_year_e}</p>
                      <p>{dayFortune?.my_year_h_kr}{dayFortune?.my_year_e_kr}</p>
                    </div>
                  </div>
                  <div className="date1">
                    <div className="th">月</div>
                    <div className="td">
                      <p>{dayFortune?.my_month_h}{dayFortune?.my_month_e}</p>
                      <p>{dayFortune?.my_month_h_kr}{dayFortune?.my_month_e_kr}</p>
                    </div>
                  </div>
                  <div className="date1">
                    <div className="th">日</div>
                    <div className="td">
                      <p>{dayFortune?.my_day_h}{dayFortune?.my_day_e}</p>
                      <p>{dayFortune?.my_day_h_kr}{dayFortune?.my_day_e_kr}</p>
                    </div>
                  </div>
                </div>
                <div className="box2">
                  { dayFortune?.Main_bad_orientation !== '' &&
                    <div>{dayFortune?.Main_bad_orientation}</div>
                  }
                  <div>충沖 : {dayFortune?.Main_conflicting}</div>
                  {dayFortune?.main_final_good_bad_day_str !== '' &&
                    <div>{dayFortune?.main_final_good_bad_day_str}</div>
                  }
                </div>
              </div>
              <div className="date-info">
                {
                  info?.length > 0 && parseInt(info[0]?.sdate) !== parseInt(moment().format('MD'))
                  ?
                  <span>{info[0]?.express_name}({moment(moment().format('YYYY') + info[0]?.sdate).format('M.D')} ~ {moment(moment().format('YYYY') + info[0]?.edate).format('M.D')})</span>
                  :
                  <span>오늘은 {info[0]?.express_name}입니다.</span>
                }
              </div>
              <div className="box3">
                {
                  (dayFortune?.main_final_good_to_do_str ?? '') !== ''
                  ? <div className="good"><span className="th">길</span><span className="td">{dayFortune?.main_final_good_to_do_str}</span></div>
                  : <div className="good"><span className="th">길</span><span className="td">없음</span></div>
                }

                {
                  (dayFortune?.main_final_bad_to_do_str ?? '') !== ''
                  ? <div className="bad"><span className="th">흉</span><span className="td">{dayFortune?.main_final_bad_to_do_str}</span></div>
                  : <div className="bad"><span className="th">흉</span><span className="td">없음</span></div>
                }
              </div>

              <NavLink to="/service?serviceSeq=7" className="link-btn">
                <span className="free" />
                오늘의 운세 더보기  &gt;&gt;
              </NavLink>
            </section>
          </section>
        </section>

        <section className="section saju-link">
          <div className="inner">
            {
              list.length > 0 &&
              list.map((item, index) => {
                return (
                  <NavLink to={"/service?serviceSeq=" + item.seq} className="box">
                    <div className="img"><img src={baseImgUrl + "/api/file/download.php?seq=" + item.uploadFileSeq} alt={item.serviceName} /></div>
                    <h4 className="box-title">{item.serviceName}</h4>
                    <p className="txt">
                      {item.summary}
                    </p>
                  </NavLink>
                )
              })
            }
          </div>
        </section>

        <section className="section saju-link1">
          <div className="inner">
            <div className="box" onClick={() => navigate('/board?boardId=stars&pageNo=1&pageRow=20')}>
              <h4 className="box-title">유명인 사주</h4>
              <p className="txt">부와 명예, 대중들의 사랑과 관심을 한 몸에 받고 있는 유명인들은 어떤 사주를 가지고 있을까?</p>
              <NavLink to="/board?boardId=stars&pageNo=1&pageRow=20" className="link-btn">자세히 보기</NavLink>
            </div>
            <div className="box" onClick={() => navigate('/board?boardId=dramas&pageNo=1&pageRow=20')}>
              <h4 className="box-title">드라마 사주</h4>
              <p className="txt">많은 사람들을 웃음짓고 눈물짓게 만든 감동의 드라마에서 논란과 이슈를 몰고 온 화제의 드라마까지, 잊을 수 없는 드라마 속 주인공들의 사주는 어떨까요?</p>
              <NavLink to="/board?boardId=dramas&pageNo=1&pageRow=20" className="link-btn">자세히 보기</NavLink>
            </div>
            <div className="box" onClick={() => navigate('/board?boardId=contents&pageNo=1&pageRow=20')}>
              <h4 className="box-title">명리학 이야기</h4>
              <p className="txt">음양오행, 주역, 사주 명리, 풍수, 토정비결 등 그 뿌리가 수천년을 이어져 내려온 인류의 소중한 유산인 동양학에 대한 재미있고 유익한 정보를 확인해 보세요.</p>
              <NavLink to="/board?boardId=contents&pageNo=1&pageRow=20" className="link-btn">자세히 보기</NavLink>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Saju;