/*****************************************************************************************
 * 설명 : 애정운·궁합
 * URI : /gunghap
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';

import { baseImgUrl } from 'config/config';

import Restful from 'service/restful';

import Layout from 'pages/homepage/layout/layout';

import './gunghap.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Gunghap = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const navigate = useNavigate();

  const [list, setList] = useState([]);

  /***************************************************************************************
   * 설명 : 서비스 데이터 로딩 처리
  ***************************************************************************************/
  const getList = () => {
    let params = {
      program: 'home',
      service: 'main',
      version: '1.0',
      action: 'getGungHapList'
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);

      } else {
        setList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    getList();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <div className="gunghap">
        <section className="main-menu mobile-only">
          <ul className="menu clearfix">
            <li><NavLink to="/" className={window.location.pathname === '/' ? 'depth1 on' : 'depth1'}>추천</NavLink></li>
            <li><NavLink to="/saju" className={window.location.pathname === '/saju' ? 'depth1 on' : 'depth1'}>사주·운세</NavLink></li>
            <li><NavLink to="/gunghap" className={window.location.pathname === '/gunghap' ? 'depth1 on' : 'depth1'}>애정운·궁합</NavLink></li>
          </ul>
        </section>

        <section className="top-visual">
          <section className="info-div">
            <h1>내 운명의 상대는 어디에?</h1>
            <p className="txt">사주인이 연인 애정운을 풀어드립니다.</p>
            <NavLink to="/service?serviceSeq=11" className="link-btn">연인 애정운 보러가기</NavLink>
          </section>
        </section>

        <section className="section gunghap-link">
          <div className="inner">
            {
              list.length > 0 &&
              list.map((item, index) => {
                return (
                  <NavLink
                    key={index}
                    to={"/service?serviceSeq=" + item.seq}
                    className="box"
                  >
                    <div className="img">
                      <img src={baseImgUrl + "/api/file/download.php?seq=" + item.uploadFileSeq} alt={item.serviceName} />
                    </div>
                    <h4 className="box-title">
                      {item.price < 1 &&
                        <span className="free" />
                      }
                      {item.serviceName}
                    </h4>
                    <p className="txt">
                      {item.summary}
                    </p>
                  </NavLink>
                )
              })
            }
          </div>
        </section>

        <section className="section saju-link1">
          <div className="inner">
            <div className="box" onClick={() => navigate('/board?boardId=stars&pageNo=1&pageRow=20')}>
              <h4 className="box-title">유명인 사주</h4>
              <p className="txt">부와 명예, 대중들의 사랑과 관심을 한 몸에 받고 있는 유명인들은 어떤 사주를 가지고 있을까?</p>
              <NavLink to="/board?boardId=stars&pageNo=1&pageRow=20" className="link-btn">자세히 보기</NavLink>
            </div>
            <div className="box" onClick={() => navigate('/board?boardId=dramas&pageNo=1&pageRow=20')}>
              <h4 className="box-title">드라마 사주</h4>
              <p className="txt">많은 사람들을 웃음짓고 눈물짓게 만든 감동의 드라마에서 논란과 이슈를 몰고 온 화제의 드라마까지, 잊을 수 없는 드라마 속 주인공들의 사주는 어떨까요?</p>
              <NavLink to="/board?boardId=dramas&pageNo=1&pageRow=20" className="link-btn">자세히 보기</NavLink>
            </div>
            <div className="box" onClick={() => navigate('/board?boardId=contents&pageNo=1&pageRow=20')}>
              <h4 className="box-title">명리학 이야기</h4>
              <p className="txt">음양오행, 주역, 사주 명리, 풍수, 토정비결 등 그 뿌리가 수천년을 이어져 내려온 인류의 소중한 유산인 동양학에 대한 재미있고 유익한 정보를 확인해 보세요.</p>
              <NavLink to="/board?boardId=contents&pageNo=1&pageRow=20" className="link-btn">자세히 보기</NavLink>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Gunghap;