/*****************************************************************************************
 * 설명 : Header
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { timeList } from 'config/config';
import { logout } from 'reducers/userReducer';

import { Restful } from 'service/restful';
import { comma } from 'service/utils';

import './header.scss';

// import {initMenuList} from 'config/menu';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import logo from 'assets/images/logo.png';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Header = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const dispatch = useDispatch();
  const [getApi] = Restful();
  const user = useSelector(state => state?.userInfo?.user);
  const history = useNavigate();

  const currentMenu = useSelector(state => state?.menuList?.current);
  const [birthHour, setBirthHour] = useState({});
  // const menuList = useSelector(state => state?.menuList?.menu);
  // const [parentMenu, setParentMenu] = useState({});

  //const {menuList, setMenuList, menuIndex, setMenuIndex} = useContext(GlobalMenu);
  // const [menuList] = useState(initMenuList);
  const [menuIndex, setMenuIndex] = useState('');

  const [menuOpen, setMenuOpen] = React.useState('');

  /***************************************************************************************
   * 설명 : 로그아웃 처리
  ***************************************************************************************/
  const setLogout = (event) => {

    if( ! window.confirm("로그아웃 처리 하시겠습니까?") ) {
      event.preventDefault();
      return false;
    }

    // 토큰 삭제
    window.localStorage.removeItem('sajuin_token');
    dispatch(logout());
    setMenuOpen(false);

    let params = {
      program: 'api',
      service: 'member',
      action: 'logout',
      version: '1.0',
    }

    // 로그인 정보 및 사이트 정보 가져오기
    getApi("get", params).then( response => {
      if( response && response.data.result ) {
        window.location.href = '/';
      }
    });
  }

  /***************************************************************************************
   * 설명 : 상위 메뉴 검색
  ***************************************************************************************/
  useEffect(() => {
    /* 향후 사용을 위해 주석처리
    let tmp = menuList.filter((item) => item?.pageCode === currentMenu?.pageCode?.substr(0, currentMenu?.pageCode?.length - 3));
    if( tmp.length > 0 )
      setParentMenu(tmp[0]);
    */

    // eslint-disable-next-line
  }, [currentMenu]);

  /***************************************************************************************
   * 설명 : 시간정보
  ***************************************************************************************/
  useEffect(() => {
    if( (user?.sjms_hour ?? '') !== '' ) {
      let tmp = timeList.filter((item) => item.value === user.sjms_hour);
      if( tmp.length > 0 )
        setBirthHour(tmp[0]);
    }

  }, [user]);

  /***************************************************************************************
   * 설명 : 서브메뉴 HTML
  ***************************************************************************************
  function SubMenu(props) {
    return (
      <li key={props.key}>
        <NavLink exact to={props.url} className="depth2">{props.title}</NavLink>
      </li>
    );
  }

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <header className={"home-header " + menuOpen}>
      <div className="inner">
        <NavLink to="/" className="logo">
          <img src={logo} className="pc-only" alt="사주인"/>
          {/* <img src={logom} className="mobile-only" alt="한국냉동공조안전관리원"/> */}
        </NavLink>
        <section className="main-menu">
          <ul className="menu clearfix">
            <li><NavLink to="/" className={window.location.pathname === '/' ? 'depth1 on' : 'depth1'}>추천</NavLink></li>
            <li><NavLink to="/saju" className={window.location.pathname === '/saju' ? 'depth1 on' : 'depth1'}>사주·운세</NavLink></li>
            <li><NavLink to="/gunghap" className={window.location.pathname === '/gunghap' ? 'depth1 on' : 'depth1'}>애정운·궁합</NavLink></li>
          </ul>
        </section>
        <section className="right-btn">
          {
            ((user?.memNo ?? '') !== '' && (user?.type ?? '') === 'member')
            ?
            <NavLink className="login-btn" onClick={(event) => setLogout(event)}>로그아웃</NavLink>
            :
            <NavLink to="/login?isCheck=1" className="login-btn">로그인</NavLink>
          }
          <Button className="menu-btn" onClick={() => setMenuOpen('open')}></Button>
        </section>


        <section className={"gnb " + menuOpen}>
          <Button className="close-btn" onClick={() => setMenuOpen('')}></Button>
          <section className="userMenu">
            <div className="user">
              <div className="userInfo">
                { // 회원정보 표시
                  ((user?.memNo ?? '') !== '' && (user?.type ?? '') === 'member') &&
                  <span>
                    <p>
                      {user.userName}
                      {(user.sjms_sex ?? '') === '' ? '' : ' / ' + user.sjms_sex }
                    </p>

                    {(user.sjms_year ?? '') !== '' && (user.sjms_month ?? '') !== '' && (user.sjms_day ?? '') !== '' &&
                    <p>
                      {user.sjms_year}년 {user.sjms_month}월 {user.sjms_day}일
                      {
                        (user.sjms_solunar ?? '') === 'solar'
                        ? '(양력)'
                        : (user.sjms_solunar ?? '') === 'lunar0'
                        ? '(음력평달)'
                        : (user.sjms_solunar ?? '') === 'lunar1'
                        ? '(음력윤달)'
                        : ''
                      }
                    </p>
                    }

                    { // 생시 표시
                      (user.sjms_hour ?? '') !== '' &&
                      <p>
                        {birthHour.label}
                      </p>
                    }
                  </span>
                }
              </div>

            { // 회원 가입 정보
              ((user?.memNo ?? '') !== '' && (user?.type ?? '') === 'member') &&
              <div className="loginInfo">
                <p>
                  {
                    user.user_type_cd === 'sj' ? '이메일로 로그인'
                    : user.user_type_cd === 'nv' ? '네이버로 로그인'
                    : user.user_type_cd === 'kk' ? '카카오로 로그인'
                    : user.user_type_cd === 'fb' ? '페이스북으로 로그인'
                    : user.user_type_cd === 'gg' ? '구글로 로그인'
                    : '이메일로 로그인'
                  }
                </p>
                <p>최초 로그인 : {moment(user.created_at).format('YYYY년 MM월 DD일')}</p>
              </div>
            }

            </div>

            {
              ((user?.memNo ?? '') !== '' && (user?.type ?? '') === 'member') &&
                <div className="box">
                  <div>
                    <p className="f14 mb5">정액상품권 보유액 : {comma(user?.subscriptionRemainderAmt ?? 0)}원</p>

                    <NavLink to="/giftcard" className="link-txt" onClick={() => setMenuOpen(false)}>정액상품권 구매하러가기 &gt;&gt;</NavLink>
                  </div>
                </div>
            }

            {
              ((user?.memNo ?? '') !== '' && (user?.type ?? '') === 'member') &&
              <Button
                className="mbtn"
                color="primary"
                variant="outlined"
                onClick={() => {
                  setMenuOpen(false);
                  history("/mypage/edit");
                }}
              >수정</Button>
            }
          </section>

          <section className={((user?.memNo ?? '') !== '' && (user?.type ?? '') === 'member') ? "menu-wrap login" : "menu-wrap"}>
            <ul className="menu">
              {
                ((user?.memNo ?? '') !== '' && (user?.type ?? '') === 'member')
                ? <li><NavLink className="depth1" onClick={(event) => setLogout(event)}>로그아웃</NavLink></li>
                : <li><NavLink to="/login?isCheck=1" className="depth1" onClick={() => setMenuOpen(false)}>로그인</NavLink></li>
              }
              { // 로그인 시 표시
                ((user?.memNo ?? '') !== '' && (user?.type ?? '') === 'member')
                ?
                <>
                  <li><NavLink to="/mypage/storageBox" className="depth1" onClick={() => setMenuOpen(false)}>운세결과 보관함</NavLink></li>
                  <li><NavLink to="/giftcard" className="depth1" onClick={() => setMenuOpen(false)}>정액상품권 구매하기</NavLink></li>
                  <li><NavLink to="/mypage/payment" className="depth1" onClick={() => setMenuOpen(false)}>정액상품권 구매내역</NavLink></li>
                </>
                :
                <>
                  <li><NavLink to={"/login?returnUrl=" + encodeURIComponent('/mypage/storageBox')} className="depth1" onClick={() => setMenuOpen(false)}>운세결과 보관함</NavLink></li>
                  <li><NavLink to={"/login?returnUrl=" + encodeURIComponent('/giftcard')} className="depth1" onClick={() => setMenuOpen(false)}>정액상품권 구매하기</NavLink></li>
                  <li><NavLink to={"/login?returnUrl=" + encodeURIComponent('/mypage/payment')} className="depth1" onClick={() => setMenuOpen(false)}>정액상품권 구매내역</NavLink></li>
                </>
              }
              <li className={ menuIndex === 1 ? 'depth1-open' : ''}>
                <div className="depth1" onClick={() => setMenuIndex(1)}>사주인 이야기<span className="icon1"><KeyboardArrowDownIcon/></span></div>
                <ul className="depth2-ul">
                  <li><NavLink to="/contents?seq=102" className="depth2" onClick={() => setMenuOpen(false)}>- 회사소개</NavLink></li>
                  <li>
                    <div className="depth2">- 로고소개</div>
                    <ul>
                      <li><NavLink to="/contents?seq=103" className="depth3" onClick={() => setMenuOpen(false)}>- 건곤감리 태극 소개</NavLink></li>
                    </ul>
                  </li>

                </ul>
              </li>
              <li className={ menuIndex === 2 ? 'depth1-open' : ''}>
                <div className="depth1" onClick={() => setMenuIndex(2)}>고객센터<span className="icon1"><KeyboardArrowDownIcon/></span></div>
                <ul className="depth2-ul">
                  <li><NavLink to="/board?boardId=notice&pageNo=1&pageRow=20" className="depth2" onClick={() => setMenuOpen(false)}>- 공지사항</NavLink></li>
                  <li><NavLink to="/qna" className="depth2" onClick={() => setMenuOpen(false)}>- 자주 묻는 질문</NavLink></li>
                  <li><NavLink to="/qna" className="depth2" onClick={() => setMenuOpen(false)}>- 문의하기</NavLink></li>
                </ul>
              </li>

            </ul>
          </section>
        </section>
      </div>
    </header>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Header;