/*****************************************************************************************
 * 설명 : 전역 앱 설정
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import { MESSAGE_DELAY } from "config/config";
import IndicatorContext from 'config/indicator';
import GlobalMenu, { initMenuList } from 'config/menu';
import { setUserInfo } from 'reducers/userReducer';

import SiteRouter from 'pages/siteRouter';

import AlertModal from 'components/alertModal';
import Indicator from 'components/indicator';
import alertMsg from 'components/message';

import { Restful } from 'service/restful';

import { setMenuCurrent, setMenuInfo, setMenuOrigin } from 'reducers/menuListReducer';
import { setSiteInfo } from 'reducers/siteInfoReducer';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'react-toastify/dist/ReactToastify.css';

import 'assets/css/common.scss';
import 'assets/css/font.css';
import 'assets/css/layout.scss';
import 'assets/css/material.scss';
import 'assets/css/modal.scss';
import 'assets/css/print.scss';
import 'assets/css/toastify.css';

import 'assets/css/contents.scss';
import 'assets/css/contentsOld.scss';

import PopupLayout from 'pages/homepage/layout/popupLayout';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const App = (props) => {

  /***************************************************************************************
   * 설명 : 메뉴 리스트
  ***************************************************************************************/
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();

  const menuOriginList = useSelector(state => state?.menuList?.list);
  const isLogout = useSelector(state => state?.userInfo.isLogout);
  const user = useSelector(state => state?.userInfo?.user);
  const indicatorList = useSelector(state => state?.indicator?.history);

  const [isMenuLoading, setIsMenuLoading] = useState(false);
  const [menuList, setMenuList] = useState(initMenuList);
  const [menuIndex, setMenuIndex] = useState(-1);
  const [menuReduce, setMenuReduce] = useState('');

  const [isPopup, setIsPopup] = useState(false);

  const [isIndicator, setIndicator] = useState(false);
  const indicator = { isIndicator, setIndicator };

  const [getApi] = Restful();

  const valueMenu = {menuList, setMenuList, menuIndex, setMenuIndex, menuReduce, setMenuReduce };

  const uri = window.location.pathname;

  /***************************************************************************************
   * 설명 : 트리 메뉴 구성 시 부모 트리 찾기
  ***************************************************************************************/
  const findMenuTree = (menuList, siteSeq, pageCode) => {
    var result;
    menuList.some(o => result = (o.pageCode === pageCode && siteSeq === o.siteSeq ) ? o : findMenuTree(o.children || [], siteSeq, pageCode));
    return result;
  }

  /***************************************************************************************
   * 설명 : 트리 메뉴 구성
  ***************************************************************************************/
  const setMenuTree = (menu) => {
    let menuList = [];
    let search = window.location.search?.replace(/\?/g, '');

    menu.forEach(item => {
      let isActive = item.linkUrl === uri && search?.indexOf((item.queryString ?? '')) > -1;
      if( uri === '/payment' && item.linkUrl === '/service'  && (item.queryString ?? '' ) !== '' && search?.indexOf((item.queryString ?? '')) > -1 ) isActive = true;
      //if( uri === '/service/result' && item.linkUrl === '/service'  && (item.queryString ?? '' ) !== '' && search?.indexOf((item.queryString ?? '')) > -1 ) isActive = true;

      let menuItem = {
        ...item,
        isActive,
        children: [],
        parent: null
      };

      if (item.pageCode.length === 3) {
        menuList.push(menuItem);

      } else {
        let parentPageCode = item.pageCode.substr(0, item.pageCode.length - 3);
        let parent = findMenuTree(menuList, item.siteSeq, parentPageCode);

        if( parent ) {
          menuItem.parent = { seq: parent?.seq, pageName: parent?.pageName };
          if( ! parent.children ) parent.children = [];

          try {
            parent.children?.push(menuItem);
          } catch(e) {
            // console.log(parent.children, menuItem);
          }
        }
      }

      if (isActive) {
        dispatch(setMenuCurrent(menuItem));
      }
    });

    return menuList;
  };

  /***************************************************************************************
   * 설명 : 서버에서 사이트(메뉴) 정보 가져오기
  ***************************************************************************************/
  const getSiteInfo = () => {
    // 첫번째 uri 보냄 사이트 구분자
    let paramsUri = uri.split(/\//gi);

    if( paramsUri[1].indexOf('admin') !== 0 && paramsUri[1].indexOf('user') !== 0  )
      paramsUri = '/';
    else
      paramsUri = paramsUri[1];

    let params = {
      program: 'api',
      service: 'cms',
      action: 'getSiteInfo',
      version: '1.0',
      domain: window.location.hostname,
      uri: paramsUri
    }

    // 로그인 정보 및 사이트 정보 가져오기
    getApi("get", params).then( response => {
      if( response && response.data.result ) {

        // 로그인 정보 저장
        if( response.data.member && response.data.member?.length > 0 )
          dispatch(setUserInfo(response.data.member[0]));

        // 사이트 정보 저장
        if( response.data.siteInfo && response.data.siteInfo.length > 0 )
          dispatch(setSiteInfo(response.data.siteInfo[0]));

        // 메뉴 리스트 정보 저장
        if( response.data.data && response.data.data.length > 0 ) {
          // 메뉴 트리 구성
          const tmp = setMenuTree(response.data.data, 0);
          setMenuList(tmp);

          // 트리 메뉴 저장
          dispatch(setMenuInfo(tmp));

          // 리스트 메뉴 저장
          dispatch(setMenuOrigin(response.data.data));
        }

        setIsMenuLoading(true);
      }
    })

  }

  /***************************************************************************************
   * 설명 : 현재 페이지 정보 저장
  ***************************************************************************************/
  useEffect(() => {
    if( isMenuLoading && menuOriginList && menuOriginList.length > 0  ) {

      let search = window.location.search?.replace(/\?/g, '');

      menuOriginList.forEach((menu) => {
        // 현재 페이지 정보 저장
        if( menu.isRouter !== 0 && menu.isRouter !== '0' ) {

          let isActive = menu.linkUrl === uri && search?.indexOf((menu.queryString ?? '')) > -1;
          if( uri === '/payment' && menu.linkUrl === '/service' && (menu.queryString ?? '' ) !== '' && search?.indexOf((menu.queryString ?? '')) > -1 ) isActive = true;

          if( isActive ) {
            let tmp = menuOriginList.filter((item) => item.siteSeq === menu.siteSeq && item.pageCode === menu.pageCode.substr(0, menu.pageCode.length - 3));
            let tmp1 = {
              ...menu,
              parentType: 0,
              parent: ( tmp.length > 0 ) ? tmp : {}
            };

            dispatch(setMenuCurrent(tmp1));

            if( parseInt(tmp1.authView) === 0 ) {
              // history('/');
            }

            if( parseInt(tmp1.isLogin) === 1 ) {
              if( (user.memNo ?? '') === '') {
                // history('/login');
              }
            }
          }
        }
      });
    }

    getSiteInfo();

    if( window.location.pathname === '/' ) {
      setIsPopup(true);
    } else {
      setIsPopup(false);
    }

    const loadGtagScript = () => {
      // gtag.js 스크립트를 동적으로 로드
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=UA-122652734-1`;
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        // 스크립트 로드 후 dataLayer와 gtag 함수 초기화
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'UA-122652734-1');
        gtag('config', 'UA-166099570-1');
      };

      script.onerror = () => {
        console.error('Google Analytics 스크립트를 로드하는 데 실패했습니다.');
      };
    };

    loadGtagScript();

    // eslint-disable-next-line
  }, [isMenuLoading, location, window.location.search]);

  /***************************************************************************************
   * 설명 : 로그아웃 체크
  ***************************************************************************************/
  useEffect(() => {
    let uri = window.location.pathname;

    if( uri.indexOf('/admin') > -1 ) {
      let token = window.localStorage.getItem('sajuin_admin_token');

      if( token === null || token.length < 10 ) {
        // 세션 로그아웃일 경우
        if( uri.indexOf('/admin') > -1 ) {
          if( uri !== '/admin/login' && uri !== '/admin' ) {
            alertMsg("관리자 로그인이 필요합니다.1", "E", MESSAGE_DELAY);
            // history( '/admin/login' );
          }
        }
      }
    }

    // eslint-disable-next-line
  }, [user, isLogout]);

  /***************************************************************************************
   * 설명 : 인디케이터 표시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( indicatorList.length < 1 )
      setIndicator(false);
    else
      setIndicator(true);
  }, [indicatorList]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      <ToastContainer
        limit={10}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        draggable
        pauseOnHover
      />
      <Indicator checked={isIndicator} />
      <AlertModal />

      <IndicatorContext.Provider value={indicator}>
        <GlobalMenu.Provider value={valueMenu}>
          <GoogleOAuthProvider clientId="965824537912-dg485pfli45774fgg8h8sq63fj0ivvl6.apps.googleusercontent.com">
            <SiteRouter
              history={history}
            ></SiteRouter>
          </GoogleOAuthProvider>

          {isPopup &&
            <PopupLayout />
          }

        </GlobalMenu.Provider>
      </IndicatorContext.Provider>
    </>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default App;